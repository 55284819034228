  html {
    font-size: 14px;
  }

  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }

  .btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
  }

  html {
    position: relative;
    min-height: 100%;
  }

  body {
    margin-bottom: 60px;
  }

  .btn-round {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #3F85E1;
      color: white;
      border: none;
      display: inline-block;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      cursor: pointer;
  }

  .btn-round:hover {
      background-color: #0056b3;
  }

  .btn-primary {
      background-color: #3F85E1;
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-radius: 8px;
  }

  .btn-primary:hover {
      background-color: #0056b3;
  }

  .btn-primary:active {
      background-color: #004080;
      box-shadow: 0 5px #666;
      transform: translateY(4px);
  }

  .btn-secondary {
      background-color: #6c757d;
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-radius: 8px;
  }

  .btn-secondary:hover {
      background-color: #545b62;
  }

  .btn-secondary:active {
      background-color: #3d434a;
      box-shadow: 0 5px #666;
      transform: translateY(4px);
  }


  input[type=text], textarea {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box; /* Pour inclure le padding dans la largeur/hauteur */
      font-size: 16px;
      width: 100%;
      margin-bottom: 10px;
  }

  textarea {
      resize: vertical; /* Permet de redimensionner verticalement uniquement */
  }

  input[type=text]:focus, textarea:focus {
      outline: none;
      border-color: #007bff; /* Couleur de la bordure lorsqu'il est focus */
      box-shadow: 0 0 5px rgba(0,123,255,0.5); /* Ombre légère pour mettre en évidence */
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;  }